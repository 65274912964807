@import '../../../styles/global.scss';

$transition-hover: .5s;

.header-media-container{
    display: flex; gap: 1rem;

    @media(max-width: $ipad){ margin-left: auto;}

    .media-item{
        position: relative;

        img{
            width: 2.5rem;
            transition: $transition-hover;
    
            &:hover{
                cursor: pointer;
                transform: scale(1.1); transition: $transition-hover;
            }
        }

        .media-bubble{
            position: absolute; top: 0; left: 50%; transform: translate(-50%,-125%);
            padding: 3px;
            border-radius: 5px;
            font-size: .85rem; color: $main-color; font-family: cursive; text-shadow: 0 1px 0 $main-color, 0 0 2px $background-theme;
            
            .triangle{
                position: absolute; bottom: 0; right: 50%; transform: translate(50%,90%);
                width: 0;  height: 0;
                border-left: 7px solid transparent; border-right: 7px solid transparent;
            }
        }
    }

}
@import '../../../../../../styles/global.scss';

.member-modal-card{
    position: fixed; left: 50%; top: 50%; transform: translate(-50%,-50%); z-index: 500;
    display: flex;
    width: 100%;
    max-width: 750px;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 7px;
    overflow: hidden;

    @media(max-width: $mobile){ 
        position: fixed; left: 50%; top: 55%; transform: translate(-50%,-50%); z-index: 500;
        flex-flow: column; width: 90%; align-items: center;
        padding: 1rem 0;
    }

    .member-picture-container{
        width: 300px; height: 300px;

        @media(max-width: $mobile) {width: 150px; height: 150px;}

        img{  
            width: 100%; height: 100%;
            @media(max-width: $mobile){ border-radius: 50%; }
        }
    }

    .member-info-container{
        width: 450px;
        color: $main-orange;
        display: flex; flex-flow: column; justify-content: space-around;

        @media(max-width: $mobile){ width: 90%; text-align: center;}

        h1{ margin: 10px 0; text-align: center; }
        p{ margin: 0; padding: 0 25px; }
        h2{ text-align: center;}
    }

    .close-member-modal{
        position: absolute; right: 7px; top: 3px;
        color: $main-orange;
        font-size: 1.5rem; font-family: cursive; font-weight: bold;

        &:hover{ cursor: pointer; color: white; }
    }
}
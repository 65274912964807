@import '../../styles/global.scss';

.games-and-fun-page{
    padding-top: $padding-top-header-fix;
    width: 100%; height: 100%;
    position: relative;

    display: flex; justify-content: space-evenly; align-items: center;

    @media(max-width: $ipad){
        height: fit-content; padding-bottom: 50px;
        flex-flow: column; align-items: center; gap: 50px;
    }
}
@import '../../styles/global.scss';

.addons-page{
    padding: calc($padding-top-header-fix + 5rem) 40px 0 40px;
    display: flex; justify-content: space-evenly; gap: 32px;
    color: $sub-color;

   @media(max-width: $ipad){ 
        flex-flow: column-reverse; gap: 100px; 
        padding: calc($padding-top-header-fix + 6rem) 40px 0 40px;
    }
   @media(max-width: $mobile) {  padding: calc($padding-top-header-fix + 8rem) 40px 0 40px; }
}
@import "../../../../styles/global.scss";

.other-website-source {
  padding: 14px 6px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background-color: rgba(14, 14, 14, 0.9);
  color: rgb(172, 172, 172);
  border: none;
  border-radius: 4px;

  @media (max-width: $mobile) {
    font-size: 0.8rem;
  }

  span {
    position: relative;
    padding: 6px 16px 6px 32px;
    border: 1px solid rgb(172, 172, 172);
    text-align: end;

    img {
      position: absolute;
      left: 2%;
      top: 0;
      transform: translate(0, 20%);
      width: 25px;
      height: 25px;
    }
  }

  &:hover {
    cursor: pointer;
    color: $main-orange;
    span {
      border: 1px solid $main-orange;
    }
  }
}

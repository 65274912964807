@import '../../../../styles/global.scss';

.wing-strike-screen-arrow-controller-container{
    padding: 1rem 1rem 0 1rem;
    display: flex; justify-content: space-between;

    .solo-right-arrow{ margin-left: auto;}

    img{
        width: 4rem;
        background-color: $background-theme;
        border-radius: 50%;

        &:hover{
            cursor: pointer;
            background-color: $main-color;
        }
    }
}
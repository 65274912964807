@import '../../../styles/global.scss';

.addon-intro-container{
    width: 50%;
    display: flex; flex-flow: column; justify-content: center; align-items: center;

    @media(max-width: $ipad){ width: 100%;}

    p{ margin: 0 0 16px 0; text-align: center;}

    ul{
        margin: 50px 0 0 0;

        h3{
            margin: 0 0 12px 0;
            text-align: center; font-size: 1.5rem;
        }

        li{ margin-bottom: 12px;}
        li::marker{ color: $main-orange; font-size: 1.15rem; }
    }
}
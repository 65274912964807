@import "../../styles/global.scss";

.weekly-news-bubble {
  @include absoluteCenter;
  z-index: 1000;

  padding: 40px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.85);

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 16px;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 10px;

    img {
      width: 50px;

      @media (max-width: $mobile) {
        width: 35px;
      }
    }

    h1 {
      margin: 0;

      letter-spacing: 1px;
      word-spacing: 5px;
      white-space: nowrap;

      @media (max-width: $mobile) {
        font-size: 22px;
      }
    }
  }

  & > img {
    width: 100%;
    max-height: 400px;

    @media (max-width: $mobile) {
      max-height: 250px;
    }
  }

  .arrow-juggle {
    animation: juggle 1s linear infinite;
    @media (max-width: $mobile) {
      width: 35px;
    }
  }

  button {
    @include button;
  }
}

@keyframes juggle {
  25% {
    transform: translateX(-25%);
  }
  75% {
    transform: translateX(25%);
  }
}

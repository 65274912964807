@import '../../../styles/global.scss';

.addon-nav{
    padding: 16px;
    position: fixed; top: calc($padding-top-header-fix - 1.25rem); left: 50%; transform: translateX(-50%); z-index: 1;
    display: flex; gap: 20px;
    border-bottom-right-radius: 25px; border-bottom-left-radius: 25px;
    border-bottom: 4px double $main-color; border-right: 4px double $main-color; border-left: 4px double $main-color;
    background-color: $background-theme;

    @media(max-width: $ipad){
        width: 90%;
        padding: 16px 4px 8px 4px;
        flex-wrap: wrap; justify-content: center;
    }

    a{
        color: $main-color;
        text-decoration: none; white-space: nowrap;
        font-weight: bold; font-size: 1.25rem;
        transition: .5s;

        @media(max-width: $ipad){ font-size: 1rem;}
        @media(max-width: $mobile) { font-size: .9rem;}

        &:hover{
            color: $main-orange;
            @include bright-hover-effect;
        }
    }
}
@import '../../styles/global.scss';

.recruitment-title{
    padding: 2rem .25rem;
    text-transform: uppercase; letter-spacing: .5em;
    border: 4px double rgba(255,255,255,.25);

    @media(max-width: $ipad){ width: 100%; font-size: 1rem; }
    @media(max-width: $mobile) { font-size: .85rem;}
  
    span{        
        padding: .5rem 0 .5rem; margin: 0 auto;
        display: block;
        font: 700 5rem/1 "Oswald", sans-serif; letter-spacing: 0; white-space: nowrap;    
        text-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
        background: url("https://i.ibb.co/RDTnNrT/animated-text-fill.png") repeat-y;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: background-slider 45s linear infinite;    
        -webkit-transform: translate3d(0,0,0);
        -webkit-backface-visibility: hidden;

        @media(max-width: $ipad){ font: 700 3rem/1 "Oswald", sans-serif; }
        @media(max-width: $mobile){ font: 700 1.5rem/1 "Oswald", sans-serif;}
    }

    div{ margin: 10px 0 0 0; line-height: 30px; }
}

@keyframes background-slider{
    0% { background-position: 0% 50%; }
    100% { background-position: 100% 50%; }
}
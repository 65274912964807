
.bg-line-effect{
    position: fixed; top: 0;
    width: 2px; height: 100vh;

    overflow: hidden;

    &::after{
        content: '';
        display: block;
        position: absolute;
        width: 100%; height: 15vh; 
        top: -50%;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
        animation: light-drop 4s 0s infinite;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
    }
}

@keyframes light-drop{
    0%{top: -50%;}
    100%{top: 110%;}
}
@import "../../../../../styles/global.scss";

$transition-hover: 0.25s;

.bingo-board-container {
  width: 75%;
  max-width: 1500px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 40px;
  padding: 32px;
  border: 4px double $main-orange;

  @media (max-width: $ipad) {
    width: 90%;
  }
  @media (max-width: $mobile) {
    width: 100%;
    justify-content: space-evenly;
    gap: 25px;
  }

  .bingo-card {
    width: 25%;
    height: 160px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    filter: drop-shadow(0 5px 10px $background-theme) contrast(1.3);

    @media (max-width: $ipad) {
      height: 140px;
    }
    @media (max-width: $mobile) {
      height: 120px;
    }

    h1,
    h4,
    p {
      margin: 0;
      z-index: 3;
      text-align: center;
      padding: 0 6px;
    }
    h1 {
      margin: 4px 0 auto 0;
      font-size: 1.3rem;
      @media (max-width: $ipad) {
        font-size: 1rem;
      }
      @media (max-width: $mobile) {
        font-size: 0.8rem;
      }
    }
    h4 {
      font-size: 1.25rem;
      margin: 0;
      @media (max-width: $ipad) {
        font-size: 0.9rem;
      }
      @media (max-width: $mobile) {
        font-size: 0.7rem;
      }
    }
    p {
      font-size: 1.2rem;
      text-shadow: 2px 2px 2px black;
      margin: 0;
      @media (max-width: $ipad) {
        font-size: 0.8rem;
      }
      @media (max-width: $mobile) {
        font-size: 0.6rem;
      }
    }

    .bingo-card-image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
    }
  }

  .idle-card {
    background-image: url("../../../../../assets/images/fractals/fractal\ background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .rolling-card {
    animation: spin 1s linear infinite;
  }
  .bingoed-card {
    transition: $transition-hover;
    border: 2px solid $background-theme;

    h1,
    h4,
    p {
      text-shadow: 1px 3px 3px $background-theme, -1px 0 2px $background-theme;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.03);
      transition: $transition-hover;
    }
  }
  .chosen-card {
    box-shadow: 0 0 16px $main-color;
  }
}

@keyframes spin {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@import '../../../styles/global.scss';

$transition-hover: .7s;

.game-card-container{
    position: relative;
    width: 450px;
    color: $sub-color;

    @media(max-width: $ipad){ width: 75%; }

    h1{
        margin: 0 0 7px 0;
        text-align: center; font-size: 2rem; text-shadow: 1px 0px 1px white; letter-spacing: 2px;
        transform: skew(3deg);
    }

    .game-card-image-container{
        position: relative; 
        border-radius: 10px;
        overflow: hidden;
        transition: $transition-hover;

        &:hover{
            cursor: pointer;
            filter: brightness(75%);
            transform: scale(1.05); transition: $transition-hover;

            span{ animation: fade-in .7s linear; }
        }

        .game-card-image{ width: 100%; height: 100%;}

        .lets-play{
            position: absolute; top:0; left: 0; z-index: 3;
            width: 100%; height: 100%;
            background-color: rgba(0, 0, 0, 0.1);

            span{
                position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%);
                font-size: 2rem; text-shadow: 0 1px 0 white; letter-spacing: 2px;
            }
        }
    }

    p{
        padding: 0 10px; margin: 20px 0 0 0;
        
        @media(max-width: $ipad){text-align: center;}
    }
}

@keyframes fade-in {
    0%{color:transparent; }
    100%{color: white; text-shadow: 0 1px 0 white;}
}


.border-style{
    position: absolute; top: 0; left: 0; z-index: 1;
    width: 100%; height: 100%;

    img{
        position: absolute;
        height: auto;
    }

    .border-bottom{ width: 100%; height: 100px; bottom: 0; left: 0;}
    .border-left{height: 100%; width: 100px; left: 0; top: 0;}
    .border-top{ width: 100%; height: 100px; top: 0; left: 0;}
    .border-right{ height: 100%; width: 100px; right: 0; top: 0;}
}
@import '../../../../../styles/global.scss';

.about-guild-container{
    text-align: center;
    
    .about-guild{
        
        h1{ margin: 0;}

        p{
            padding: 0 50px;

            @media(max-width: $mobile){ padding: 0 25px;}

            a{
                font-weight: bold; text-decoration: none;
                color: white;

                &:hover{ cursor: pointer; color: orange; }
            }
        }
    }
}
@import '../../styles/global.scss';

.animated-title-crossed-underline{
    position: relative;
    text-align: center;

    ul{
        li{
            max-width: 2em;
            display: inline-block;
            font-weight: 700; font-size: 2.25rem;
            transition: all 0.5s ease-in-out;

            @media(max-width: $ipad) { font-size: 1.75rem;}
        }
        .hidden,.ghost{ opacity: 0; max-width: 0; }
    }

    .title-cross-underline{
        width: 45%; height: 2px;
        position: absolute; left: 50%; bottom: 0; transform: translate(-45%,0);
        display: flex; justify-content: flex-start;
        background-color: rgba(255,255,255,.5);
        animation: widen-line .25s linear; animation-delay: .25s;

        @media(max-width: $ipad) {height: 1px;}
        @media(max-width: $mobile) {width: 70%;}
        
        div{
            width: 15px; height: 2px;
            background-color: rgba(255,255,255,.5);
            transform: rotate(-65deg);
        }
    }
}

@keyframes widen-line{
0%{ width: 0;}
100%{width: 35%;}
}
@import '../../../../../styles/global.scss';

.member-wrapper{
    width: 250px; height: 250px;
    position: relative;
    border-radius: 50%; outline: 1px solid rgba(255, 255, 255, 0.5); z-index: 12;
    overflow: hidden;
    filter:brightness(75%); transition: .5s;

    @media(max-width: $ipad){ width: 200px; height: 200px;}
    @media(max-width: $mobile) { width: 300px; height: 300px;}

    &:hover{ @include bright-hover-effect;}
    
    img{ width: 100%; height: 100%;}
    span{
        position: absolute; bottom: 10px; left: 50%; transform: translateX(-50%);
        font-size: 1.25rem; font-weight: bold; letter-spacing: 2px;
        text-shadow: 2px 2px 2px $background-theme;
    }
}
@import '../../styles/global.scss';

.background-image-wrapper{
    position: absolute; top: 0; left: 0; z-index: -1;
    width: 100%; height: 100%;

    img{ 
        width: 100%; height: 100%;
        @media(max-width: $mobile){ height: fit-content;}
    }
}
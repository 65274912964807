.frame-wrapper {
  position: relative;
  overflow: hidden;
  padding: 10px;
  margin: 0 15px 0 15px;
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 15px black;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.15);

  img {
    width: 100%;
    height: 100%;
    border-radius: 7px;
  }

  .frame-bg-wall {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    animation: fade-in 0.4s linear;

    span {
      font-size: 1.3rem;
      font-weight: bold;
      white-space: nowrap;
      text-shadow: 0 0 3px #fff;
      letter-spacing: 1px;
      opacity: 0.85;
    }
  }
}

@keyframes fade-in {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@import '../../../../styles/global.scss';

.wing-strike-intro-container{
    position: absolute; left: 32px; bottom: 50px;
    display: flex; flex-flow: column;
    padding: .5rem 2rem 1rem 2rem; width: 400px;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 25px;

    @media(max-width: $mobile){
        width: 100%; max-width: calc(100vw - 20px - 4rem);
        left: 10px; 
    }

    h1{
        margin: 0;
        text-transform: uppercase; font-size: 3rem; letter-spacing: 5px; white-space: nowrap; text-shadow: 1px 1px 1px white;

        @media(max-width: $mobile){text-align: center;}
    }

    h2{
        margin: 10px 0;
        text-decoration: underline; letter-spacing: 1px; white-space: nowrap;
    }

    ul{
        margin: 0 0 16px 16px; padding: 0;
        li::marker{ color: $main-orange;}
    }

    button{ 
        @include button;
        background-color: rgba(0, 0, 0, 0.5);
        transition: .5s;
    }
}
@import '../../styles/global.scss';

.loader-container{
    margin: 50px 0;
    display: flex; justify-content: center; align-items: center;
}

.loader {
    display: inline-block;
    color: $main-orange;
    font-weight: bold; text-align: center; font-size: 1.5rem; text-shadow: 0 0 1px black;
}

.loader:after {
    content: "";
    display: block;
    width: 5rem; height: 5rem;
    border-radius: 50%; border: 8px solid $main-orange; border-color: $main-orange transparent $main-orange transparent;
    animation: loading 2.5s linear infinite;
}

@keyframes loading {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
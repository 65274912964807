@import '../../../styles/global.scss';

$special-case-media-large-mobile: 1000px;

.addon-information-container{
    width: 100%; padding-bottom: 2rem;
    display: flex; justify-content: space-evenly; align-items: center;

    @media(max-width: $special-case-media-large-mobile){ flex-flow: column; gap: 32px;}

    .addon-information-wrapper{
        width: 50%;
        display: flex; flex-flow: column;

        @media(max-width: $ipad){ width: 70%;}
        @media(max-width: $special-case-media-large-mobile){ width: 100%;}

        .addon-title{
            display: flex; justify-content: center; align-items: center; gap: 16px;

            h1{
                margin: 0;
                font-size: 2.5rem; letter-spacing: 2px; white-space: nowrap; text-shadow: 1px 1px 1px $main-color;
                transform: skew(7deg);

                @media(max-width: $special-case-media-large-mobile){ font-size: 2rem; }
                @media(max-width: $mobile){ font-size: 1.5rem;}
            }
        }

        p{
            margin: 0; padding: 0 24px;
            text-align: center; font-size: 1.15rem; font-weight: bold; letter-spacing: 2px;

            @media(max-width: $special-case-media-large-mobile){ font-size: 1.05rem;}
            @media(max-width: $mobile){ font-size: 1rem; padding: 0 12px; }
        }

        hr{
            width: 93%; margin: 24px auto;
            @media(max-width: $mobile){ margin: 12 auto; width: 100%;}
        }

        ul{
            padding: 0 48px; margin: 0;

            @media(max-width: $mobile){ padding: 0 0 0 12px;}

            li::marker{ color: $main-orange; font-size: 1.15rem; }
            li{
                margin-bottom: 16px;
                font-size: 1.05rem; text-shadow: 0 0 3px $main-color;

                @media(max-width: $special-case-media-large-mobile){ font-size: 1rem;}
                @media(max-width: $mobile){ font-size: .95rem;}
            }
        }

        button{
            margin: 0 auto;
            @include button;
        }
    }

    .addon-information-image-wrapper{
        img{
            width: 500px; height: 500px;

            @media(max-width: $ipad){ width: 400px; height: 450px;}
            @media(max-width: $special-case-media-large-mobile){ width: 100%;}
        }
    }
}